import {
  DatatableColumnFromAPI,
  DatatableColumn,
  StringIndexable,
  DefaultFilter
} from "../types";

const DATA_TYPE_REGEX = /^(\w*)(?:\((\d+)(,(\d+))?\))?$/;
const SERVER_TYPE_TO_MODEL_TYPE: StringIndexable = {
  text: "string",
  String: "string",
  Date: "date",
  Integer: "integer",
  datetime: "datetime",
  timestamp: "datetime",
  double: "double",
  BigDecimal: "decimal",
  boolean: "boolean"
};

// A columns dateFormat is not currently persisted, so we generate its default value here
const setColumnDateFormat = (columnType: string) => {
  if (columnType === "date") return "YYYY-MM-DD";
  if (columnType === "datetime") return "YYYY-MM-DD HH:MM:SS";

  return "";
};

const convertColumnTypes = (
  columns: DatatableColumnFromAPI[] | null,
  indexKeys: String[],
  primaryKeys: String[],
  defaultColumns: String[] | null,
  defaultFilters?: DefaultFilter[],
  partitions?: string[]
) => {
  if (!columns) return [];

  return columns.map(c => {
    // expecting a match of length 5:
    // - 1 matched input
    // - 1 required capture group for base type
    // - 2 optional capture groups for precision and scale
    // - 1 optional capture comma and scale (ignore)
    const matches = DATA_TYPE_REGEX.exec(c.type);
    const serverBaseType = matches && matches.length === 5 ? matches[1] : "";
    const modelBaseType = SERVER_TYPE_TO_MODEL_TYPE[serverBaseType];

    const columnDefaultFilters = defaultFilters?.filter(f => f.key === c.name);

    const result: DatatableColumn = {
      name: c.name,
      type: modelBaseType,
      baseType: modelBaseType,
      dateFormat: setColumnDateFormat(modelBaseType),
      isNew: false,
      isPrimaryKey: primaryKeys.includes(c.name),
      isIndex: indexKeys.includes(c.name),
      isPartitioned: partitions?.includes(c.name) || false,
      isSampleColumn: defaultColumns?.includes(c.name) || false,
      defaultFilters: columnDefaultFilters
    };

    if (modelBaseType === "decimal" && matches) {
      result.precision = parseInt(matches[2], 10);
      result.scale = parseInt(matches[4], 10);
      result.type = `decimal(${result.precision},${result.scale})`;
    }

    return result;
  });
};

export default convertColumnTypes;
