export const DATATABLE_CODE_INFO =
  "Use a simple and unique code to identify this data table. Example is OPT for Options.";

export const SCHEMA_COLUMN_ORDER_INFO =
  "This is order of the columns that exists in the data table";
export const SCHEMA_DATE_FORMAT_INFO =
  "The database stores the date as the following format";
export const SCHEMA_DATETIME_FORMAT_INFO =
  "The database stores the datetime as the following format";
export const SCHEMA_PRECISION_INFO =
  "Precision is the number of digits in a number.";
export const SCHEMA_SCALE_INFO =
  "Scale is the number of digits to the right of the decimal point in a number.";
export const SCHEMA_INDEX_INFO =
  "Enable this if you would like to allow users to use this column to filter data. You can select up to 4.";
export const SCHEMA_PRIMARY_KEY_INFO =
  "Data in this column is unique and can be used to identify each row of data.";
export const SCHEMA_SAMPLE_COLUMN_INFO =
  "Allows the data in the column to be displayed in the sample data.";

export const FILE_FORMAT_DELIMITER_INFO =
  "This is the character used in the data file to separate data points.";
export const FILE_FORMAT_SKIP_ROWS_INFO =
  "Number of rows at the top of the file to skip during data import.";
export const FILE_FORMAT_SKIP_BOTTOM_ROWS_INFO =
  "Number of rows at the bottom of the file to skip during data import.";
export const FILE_FORMAT_ENCODING_INFO =
  "Encoding that was used to save your CSV file.";

export const SAMPLE_FILE_UPLOAD_INFO =
  "Upload a single CSV file that is no larger than 4.29 GB. If it's a ZIP file, it should only contain one single CSV file.<br> Please double check the file size and ensure that the file is not empty.";

export const SAMPLE_FILE_UPLOAD_PARQUET_INFO =
  "Upload a single Parquet file that is no longer than 2GB.";

export const DATA_LOCATION_REMOTE_PATH_INFO =
  "Enter the remote path to or from which you would like files and folders transferred. For e.g. /users/sharadar/SF1/.*.csv or /users/sharadar/SF1/.*.zip.";
export const DATA_LOCATION_FILE_PATTERN_INFO =
  "A file name could be the title of a file and file extension. For e.g- Options_Intraday_2022.csv or Options_Intraday_2022.zip.";
export const DATA_LOCATION_ACTION_INFO =
  "Choose Update if you only want to upload new data or changed data (requires primary key). Choose Replace if you want to replace the current table with the entirety of the data file.";
