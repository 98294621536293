import React from "react";
import { Modal, ModalBody, ModalHeader } from "@nef/core";
import { useDropzone, FileRejection } from "react-dropzone";
import { toast } from "react-toastify";
import { isValid, parseISO } from "date-fns";
// @ts-ignore
import { ParquetReader } from "parquetjs-lite";

import PARQUET_FILE_ICON from "../../../assets/parquet-file.svg";
import MoreInfoTooltip from "../../MoreInfoTooltip";
import Toast from "../../Toast";
import { ParseResult } from "../../../lib/csv-reader";
import { AccumulatedColumnStats } from "../../../api/types";
import { SAMPLE_FILE_UPLOAD_PARQUET_INFO } from "../hints";

import styles from "./AutoSchemaPopulateModal.module.scss";

interface AutoSchemaPopulateModalProps {
  isOpen: boolean;
  close: () => void;
  onProcessed: (accumulated: AccumulatedColumnStats, fileType: String) => void;
  isModalOpen: (bool: boolean) => void;
}

const MAX_FILE_SIZE = 2048 * 1024 * 1024; // we don't store the file anyway, it's just used to infer data columns.

const ERROR_CODE_TO_MESSAGES: { [key: string]: string } = {
  "file-invalid-type": "Data sample must be a .parquet file",
  "file-too-large": "Data sample must be smaller than 2GB"
};

const mapErrorCodeToMessage = (code: string) => {
  return ERROR_CODE_TO_MESSAGES[code] || "";
};

const AutoSchemaPopulateParquetModal = ({
  isOpen,
  close,
  onProcessed,
  isModalOpen
}: AutoSchemaPopulateModalProps) => {
  const evaulateColumnType = (value: string) => {
    const sanitizedValue = value.trim();

    if (!Number.isNaN(Number(sanitizedValue))) {
      const numberParts = sanitizedValue.split(".");

      // whole = precision - scale. Tracking number of whole number and decimal digits separately
      // in order to calculate the final precision that applies to all instances in the same column
      const whole = numberParts[0] || "";
      const decimal = numberParts[1] || "";

      return { type: "number", whole: whole.length, scale: decimal.length };
    }

    if (isValid(parseISO(sanitizedValue))) {
      return { type: "date" };
    }

    return { type: "string" };
  };

  /* eslint-disable no-param-reassign */
  const aggregateData = (
    accumulated: AccumulatedColumnStats,
    chunk: ParseResult
  ) => {
    chunk.header?.forEach((headerName, columnIndex) => {
      accumulated[columnIndex] = accumulated[columnIndex] || {
        types: {},
        header: headerName
      };
    });

    chunk.lines.forEach(values => {
      values.forEach((value, columnIndex) => {
        accumulated[columnIndex] = accumulated[columnIndex] || {
          types: {},
          header: ""
        };
        const typeObject = evaulateColumnType(value);
        const { type } = typeObject;

        accumulated[columnIndex].types[type] = accumulated[columnIndex].types[
          type
        ] || {
          type,
          count: 0
        };

        accumulated[columnIndex].types[type].count++;

        if (type === "number") {
          const maxWhole = accumulated[columnIndex].types[type].whole || 0;
          const maxScale = accumulated[columnIndex].types[type].scale || 0;
          accumulated[columnIndex].types[type].whole = Math.max(
            maxWhole,
            typeObject.whole || 0
          );
          accumulated[columnIndex].types[type].scale = Math.max(
            maxScale,
            typeObject.scale || 0
          );
        }
      });
    });
  };
  /* eslint-enable no-param-reassign */

  const onDropAccepted = async (acceptedFiles: File[]) => {
    try {
      const file = acceptedFiles[0];
      const accumulated: AccumulatedColumnStats = {};
      const buffer = await file.arrayBuffer();
      const reader = await ParquetReader.openBuffer(Buffer.from(buffer));
      const cursor = reader.getCursor();
      let chunk: false | ParseResult = false;

      do {
        try {
          // eslint-disable-next-line no-await-in-loop
          chunk = await cursor.next();
          if (chunk) {
            chunk = fetchParsedResult(chunk);
            aggregateData(accumulated, chunk);
          }
        } catch (e) {
          // cursor.next() failed, so we try to infer datatable schema from parquet's schema
          aggregateData(
            accumulated,
            fetchParsedResult(fetchFields(cursor.schema.fieldList))
          );
          break; // mandatory to break, since we already have all data needed, no need to iterate over fieldList.
        }
      } while (chunk && chunk.lines.length);
      onProcessed(accumulated, "Parquet");
      isModalOpen(false);
    } catch (e) {
      toast(
        <Toast
          type="error"
          title="File upload failed"
          details={[
            {
              message:
                "Your Parquet file cannot be processed to auto-generate datatable schema."
            }
          ]}
        />
      );
    }
  };

  const fetchParsedResult = (fields: any) => {
    const keys = Object.keys(fields);
    const values = Object.values(fields);
    return {
      header: keys,
      lines: [values as string[]]
    };
  };

  const fetchFields = (fieldList: any) => {
    const fields: { [key: string]: string } = {};
    if (fieldList.length) {
      fieldList.forEach(
        // eslint-disable-next-line no-loop-func
        (field: { name: any; primitiveType: any; originalType: any }) => {
          const name: string = field.name.toString();
          const value = inferDataTypeFromName(
            name,
            field.primitiveType,
            field.originalType
          );
          fields[name] = value;
        }
      );
    }
    return fields;
  };

  const inferDataTypeFromName = (
    name: String,
    primitiveType: any,
    originalType: any
  ) => {
    if (name.includes("is_")) return "true";
    if (name.includes("_id")) return "0";
    if (originalType === "UTF8" || primitiveType === "BYTE_ARRAY")
      return "string";
    return "string";
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const errorDetails = fileRejections
      .map(({ errors }) => {
        return errors.map(error => {
          return { message: mapErrorCodeToMessage(error.code) };
        });
      })
      .flat();

    toast(
      <Toast type="error" title="File upload failed" details={errorDetails} />
    );
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: true,
    accept: {
      "text/parquet": [".parquet"]
    },
    maxSize: MAX_FILE_SIZE
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={close}
      size="lg"
      data-testid="autoSchemaPopulateParquetModal"
      closeOnOutsideClick={false}
    >
      <ModalHeader toggle={close} />
      <ModalBody>
        <div
          className={`${styles.dropzone} ${
            isDragActive && styles["dropzone-active"]
          }`}
          {...getRootProps({
            maxfiles: 1,
            multiple: false
          })}
          data-testid="autoSchemaPopulateModal_dropzone"
        >
          <label htmlFor="upload-file">
            <input
              {...getInputProps()}
              id="upload-file"
              data-testid="autoSchemaPopulateModalParquet_upload"
            />
            <img
              src={PARQUET_FILE_ICON}
              className={styles["file-icon"]}
              alt="CSV file icon"
            />
            Drag and drop to upload or&nbsp;
            <span
              role="button"
              className={styles.browse}
              onClick={open}
              onKeyPress={open}
              tabIndex={-1}
            >
              browse
            </span>
            &nbsp;
            <MoreInfoTooltip description={SAMPLE_FILE_UPLOAD_PARQUET_INFO} />
          </label>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AutoSchemaPopulateParquetModal;
