import React, { useCallback, useContext, useState } from "react";
import {
  FlexGrid,
  FormSelect,
  FormField,
  Box,
  Header,
  Button
} from "@nef/core";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useMutation } from "jsonapi-react";
import { decamelizeKeys } from "humps";
import { generatePath, useHistory } from "react-router-dom";
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import DatatableContext from "../../contexts/datatable";
import { extractErrors } from "../../utils/react-hook-form-utils";
import { Step } from "../wizard";
import Toast from "../Toast";
import {
  DatatableSchema,
  DatatableSchemaSource,
  StringIndexable
} from "../../api/types";
import createDatatableBody, {
  sanitizeSource
} from "../../api/normalizers/datatable";
import { ConfirmationModalPromisified } from "../modals/ConfirmationModal";
import LabelValueWithHint from "../common/LabelValueWithHint";
import globalStyles from "../../styles/common.module.scss";
import extractFormSelectOnChangeValue from "../../utils/nef-utils";
import { PATHS } from "../../routes";

import {
  DATA_LOCATION_ACTION_INFO,
  DATA_LOCATION_FILE_PATTERN_INFO,
  DATA_LOCATION_REMOTE_PATH_INFO
} from "./hints";
import DataLocationCredentialInput from "./components/DataLocationCredentialInput";

type CronTimeOption = {
  value: null | string;
  label: string;
};

const PUBLISHED_CRON_VALUE = "*/20 * * * *";

const CRON_TIME_OPTIONS: CronTimeOption[] = [
  { value: PUBLISHED_CRON_VALUE, label: "Enabled" },
  { value: "", label: "Disabled" }
];

const TYPE_OPTIONS = ["s3", "ftp", "sftp"].map(value => {
  return {
    value,
    label: value
  };
});

const ACTION_OPTIONS = ["update", "replace"].map(value => {
  return {
    value,
    label: value
  };
});

const DEFAULT_SOURCE_INFO = {
  host: "",
  type: "",
  username: "",
  password: "",
  cronTime: "",
  filePattern: "",
  action: ""
};

const DataLocationStep = () => {
  const history = useHistory();
  const { datatable } = useContext(DatatableContext);
  const [isSavingDataLocation, setSavingDataLocation] = useState(false);

  const datatableId = `${datatable.vendorCode}/${datatable.code}/${datatable.version.code}`;

  const [editDatatable] = useMutation<DatatableSchema>(
    ["datatable_schema", datatableId],
    { method: "PUT" }
  );

  const [createDatatable] = useMutation("datatable_schema", {
    invalidate: false
  });

  const dataLocationSchema = yup.object({
    host: yup.string().required(),
    type: yup
      .string()
      .oneOf(TYPE_OPTIONS.map(o => o.value))
      .required(),
    filePattern: yup.string().required("File pattern cannot be blank"),
    action: yup.string().oneOf(["update", "replace"]).required(),
    username: yup.string().when("type", {
      is: (type: string) => ["ftp", "sftp"].includes(type),
      then: yup.string().required(),
      otherwise: yup.string().notRequired()
    }),
    password: yup.string().when("type", {
      is: (type: string) => ["ftp", "sftp"].includes(type),
      then: yup.string().required(),
      otherwise: yup.string().notRequired()
    }),
    cronTime: yup
      .string()
      .label("Data File Upload")
      .oneOf(
        CRON_TIME_OPTIONS.map(c => c.value),
        "Data File Upload is missing a value"
      )
      .nullable()
  });

  const initialValues = () => {
    const initialValue = datatable.source || DEFAULT_SOURCE_INFO;

    return Object.entries(initialValue).reduce((acc, curr) => {
      const [key, value] = curr;
      acc[key] = value || "";
      return acc;
    }, {} as StringIndexable);
  };

  const methods = useForm({
    defaultValues: initialValues(),
    resolver: yupResolver(dataLocationSchema)
  });
  const { control, handleSubmit } = methods;

  const onPublishDatatable: SubmitHandler<
    DatatableSchemaSource
  > = async newDatatableSchemaSource => {
    const datatableInfo = {
      ...datatable,
      source: newDatatableSchemaSource
    };

    datatableInfo.source.cronTime = PUBLISHED_CRON_VALUE;

    const normalizedDatatable: any = createDatatableBody(datatableInfo, true);

    createDatatable(normalizedDatatable).then(response => {
      const { error, errors } = response;

      if (error || errors) {
        const responseErrors = error ? [error] : errors;

        if (!responseErrors) return;

        const formattedErrors = responseErrors.map(e => ({
          message: e.detail
        }));

        toast(
          <Toast
            type="error"
            title="Data Table cannot be published"
            details={formattedErrors}
          />
        );

        return;
      }

      toast(
        <Toast
          type="success"
          title="Data Table has been published"
          details={[{ message: "Data Table has been published" }]}
        />
      );

      history.push(
        generatePath(`${PATHS.EDIT_DATATABLE}/dataLocation`, {
          vendorCode: datatable.vendorCode,
          datatableCode: datatable.code,
          versionCode: "FINAL"
        })
      );
    });
  };

  const onSaveDatatable: SubmitHandler<
    DatatableSchemaSource
  > = async newDatatableSchemaSource => {
    const requestBody: any = {
      source: decamelizeKeys(sanitizeSource(newDatatableSchemaSource), {
        separator: "_"
      })
    };

    return editDatatable(requestBody).then(response => {
      const { error } = response;

      if (error) return Promise.reject(error);

      return true;
    });
  };

  const datatableIsPublished = datatable.version.code === "FINAL";

  const onSubmitError: SubmitErrorHandler<StringIndexable> = errors => {
    setSavingDataLocation(false);

    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );

    return false;
  };

  const openPublishModalOrSubmit = async () => {
    if (!datatableIsPublished) {
      await ConfirmationModalPromisified({
        question:
          "This data table is about to be published. You will no longer be able to update table schema nor upload sample data. Are you sure?"
      })
        .then(submitStep)
        .catch(_ => _);

      return;
    }

    await submitStep();
  };

  const submitStep = useCallback(async () => {
    return handleSubmit(async newDatatableSchemaSource => {
      // 1) Submit Data Location Form
      try {
        await onSaveDatatable(
          newDatatableSchemaSource as DatatableSchemaSource
        );
      } catch (error: any) {
        toast(
          <Toast
            type="error"
            title="Data Location cannot be updated"
            details={[{ message: error.detail }]}
          />
        );
        return;
      }

      // 2) Test Connection
      // try {
      //   await testDataLocationConnect(
      //     datatable.vendorCode,
      //     datatable.code,
      //     datatable.version.code
      //   );
      // } catch (error: any) {
      //   const errorMessage = error.response.data.errors?.length
      //     ? error.response.data.errors[0].detail // errors coming from wikiposit
      //     : error.response.data?.message; // errors coming from fetcher

      //   toast(
      //     <Toast
      //       type="error"
      //       title="Failed to connect to your data location"
      //       details={[{ message: errorMessage }]}
      //     />
      //   );
      //   return;
      // }

      if (datatableIsPublished) {
        toast(
          <Toast
            type="success"
            title="Success"
            details={[{ message: "Data Location has been updated" }]}
          />
        );
        return;
      }

      // 3) Publish if unpublished
      await onPublishDatatable(
        newDatatableSchemaSource as DatatableSchemaSource
      );
    }, onSubmitError)();
  }, [datatable.fileFormat, datatableIsPublished]);

  const saveLocationInfo = useCallback(async () => {
    setSavingDataLocation(true);

    handleSubmit(async newDatatableSchemaSource => {
      try {
        await onSaveDatatable(
          newDatatableSchemaSource as DatatableSchemaSource
        );

        toast(
          <Toast
            type="success"
            title="Success"
            details={[{ message: "Data Location has been updated" }]}
          />
        );
      } catch (error: any) {
        toast(
          <Toast
            type="error"
            title="Data Location cannot be updated"
            details={[{ message: error.detail }]}
          />
        );
      }

      setSavingDataLocation(false);
    }, onSubmitError)();
  }, [datatable.fileFormat]);

  return (
    <Step
      name="Data Location"
      path="dataLocation"
      canAdvance={true}
      save={() => openPublishModalOrSubmit()}
    >
      <FlexGrid fluid={true} data-testid="datatableLocationStep_form">
        <FlexGrid.Row>
          <FlexGrid.Column xs={24} md={12}>
            <Box paddingBottom={4}>
              <Header size={1}>Data Location</Header>
              <div className={globalStyles.subheader}>
                This section allows you to define the automated ingestion of the
                data files. You can define where the file will be stored and how
                to access it. Once you provide the remote path, you can test
                connectivity. If connectivity is successful, feel free to
                publish the table when you are ready. If connectivity needs to
                be remediated, please use these instructions to do so and then
                test connectivity again:{" "}
                <a href="https://confluence.org.nasdaqomx.com/display/GGIS/Cross+Account+Bucket+Requests">
                  Instructions
                </a>
              </div>
            </Box>
          </FlexGrid.Column>
        </FlexGrid.Row>
        <FlexGrid.Row>
          <FlexGrid.Column md={12} lg={8}>
            <Controller
              name="host"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <FormField
                    id="host"
                    name={name}
                    type="text"
                    placeholder="Enter remote path"
                    label={
                      (
                        <LabelValueWithHint
                          label="Remote Path"
                          hint={DATA_LOCATION_REMOTE_PATH_INFO}
                        />
                      ) as any
                    }
                    value={value}
                    onChange={onChange}
                    data-testid="dataLocationStep_host"
                  />
                );
              }}
            />
          </FlexGrid.Column>
          <FlexGrid.Column md={12} lg={6}>
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, name } }) => (
                <FormSelect
                  id="type"
                  name={name}
                  label="Connection Type"
                  options={TYPE_OPTIONS}
                  onChange={(formSelectOnChangeParam: any) => {
                    onChange(
                      extractFormSelectOnChangeValue(formSelectOnChangeParam)
                    );
                  }}
                  defaultValue={TYPE_OPTIONS[0]}
                  isClearable={false}
                  disabled={true}
                  classNamePrefix="type"
                />
              )}
            />
          </FlexGrid.Column>
          <FormProvider {...methods}>
            <DataLocationCredentialInput />
          </FormProvider>
        </FlexGrid.Row>
        <FlexGrid.Row>
          <FlexGrid.Column md={8} lg={4}>
            <Controller
              name="sourceFormat"
              control={control}
              render={({ field: { name } }) => {
                return (
                  <FormField
                    id="sourceFormat"
                    name={name}
                    label="Source Format"
                    type="text"
                    placeholder="parquet"
                    disabled={true}
                    data-testid="dataLocationStep_filePattern"
                  />
                );
              }}
            />
          </FlexGrid.Column>
          <FlexGrid.Column md={8} lg={4}>
            <Controller
              name="filePattern"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <FormField
                    id="filePattern"
                    name={name}
                    type="text"
                    placeholder="Enter file pattern"
                    label={
                      (
                        <LabelValueWithHint
                          label="File Pattern"
                          hint={DATA_LOCATION_FILE_PATTERN_INFO}
                        />
                      ) as any
                    }
                    value={value}
                    onChange={onChange}
                    data-testid="dataLocationStep_filePattern"
                  />
                );
              }}
            />
          </FlexGrid.Column>
          <FlexGrid.Column md={8} lg={4}>
            <Controller
              name="action"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <FormSelect
                  id="action"
                  name={name}
                  label={
                    (
                      <LabelValueWithHint
                        label="Data Upload Action"
                        hint={DATA_LOCATION_ACTION_INFO}
                      />
                    ) as any
                  }
                  options={ACTION_OPTIONS}
                  onChange={(formSelectOnChangeParam: any) => {
                    onChange(
                      extractFormSelectOnChangeValue(formSelectOnChangeParam)
                    );
                  }}
                  value={ACTION_OPTIONS.find(a => a.value === value)}
                  isClearable={false}
                  classNamePrefix="action"
                />
              )}
            />
          </FlexGrid.Column>
        </FlexGrid.Row>
        <FlexGrid.Row>
          <FlexGrid.Column>
            {!datatableIsPublished && (
              <Button
                onClick={saveLocationInfo}
                data-testid="dataLocationStep_saveDataLocation"
                disabled={isSavingDataLocation}
              >
                {isSavingDataLocation ? "Saving" : "Save"}
              </Button>
            )}
          </FlexGrid.Column>
        </FlexGrid.Row>
      </FlexGrid>
    </Step>
  );
};

export default DataLocationStep;
