import React, { useState } from "react";

import { Wizard } from "../wizard";

import Header from "./Header";
import DatatableSchemaStep from "./DatatableSchemaStep";
import DataLocationStep from "./DataLocationStep";

const EditDatatableForm = () => {
  const [backend, setBackend] = useState("postgres");
  return (
    <Wizard header={<Header backend={backend} />}>
      <DatatableSchemaStep createMode={false} setBackend={setBackend} />
      <DataLocationStep />
    </Wizard>
  );
};

export default EditDatatableForm;
